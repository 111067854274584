import { createStore } from 'vuex';
import { config } from 'vuex-module-decorators';

import { IControlState } from '@/store/modules/control.module';
import { IUserConfigState } from '@/store/modules/user-config.module';

// Root state, add your module type here to get type checking and autocomplete
// when using a module in a component / view.
export interface IRootState {
  control: IControlState;
  userConfig: IUserConfigState;
}

// Throw a raw error instead of a vuex module decorator wrapped error
// https://github.com/championswimmer/vuex-module-decorators/issues/224
config.rawError = true;

// Declare empty store first, dynamically register all modules later.
export const store = createStore<IRootState>({
  strict: false,
});
