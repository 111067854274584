import { ColDef, ColumnState } from 'ag-grid-enterprise';

import * as CabinClassColumns from '@/models/columns/definitions';
import { ColumnId } from '@/models/enums/grid';
import { generateAncillaryClassColumns } from '@/modules/revenues/columns/shared';
import { InventoryManagementMethodology } from '@/modules/shared/shared-contracts';
import { UserConfigModule } from '@/store/modules/user-config.module';

export function getInventorySharedFlightClassColumns(bookingsPickupDays: number[]): ColDef[] {
  return [
    CabinClassColumns.FlightLineCodeColumn,
    CabinClassColumns.ParentClassColumn,
    CabinClassColumns.FareValueColumn,
    CabinClassColumns.PublishedFareColumn,
    CabinClassColumns.BookingsColumn,
    CabinClassColumns.GroupBookingsColumn,
    CabinClassColumns.BookingsExGroupColumn,
    ...CabinClassColumns.generateBookingsPickupCols('class', bookingsPickupDays),
    CabinClassColumns.AverageFareColumn,
    CabinClassColumns.TotalFareRevenueColumn,
    CabinClassColumns.AuthorizationUnitsColumn,
    CabinClassColumns.ProtectionColumn,
    CabinClassColumns.UpdatedAuthorizationUnitsColumn(
      UserConfigModule.inventoryManagementMethodology === InventoryManagementMethodology.au,
    ),
    CabinClassColumns.UpdatedProtectionColumn(UserConfigModule.inventoryManagementMethodology === InventoryManagementMethodology.pr),
    CabinClassColumns.SegmentAvailabilityColumn,
    CabinClassColumns.SeatAvailabilityColumn(true),
    CabinClassColumns.RuleAuthorizationUnitsColumn,
    CabinClassColumns.BookingAvailabilityStatusColumn,
    CabinClassColumns.WaitlistColumn,
    ...generateAncillaryClassColumns(),
  ];
}

export function generateInventorySharedFlightClassColumnsState(bookingsPickupDays: number[]): ColumnState[] {
  return [
    { colId: ColumnId.FlightLineCode, hide: false, sort: null },
    { colId: ColumnId.ParentClass, hide: true, sort: null },
    { colId: ColumnId.FareValue, hide: false, sort: null },
    { colId: ColumnId.PublishedFare, hide: true, sort: null },
    { colId: ColumnId.Bookings, hide: false, sort: null },
    { colId: ColumnId.GroupBookings, hide: false, sort: null },
    { colId: ColumnId.BookingsExGroup, hide: true, sort: null },
    ...CabinClassColumns.generateBookingsPickupCols('class', bookingsPickupDays).flatMap((pickupDayColDef) => ({
      colId: pickupDayColDef.colId as string,
      hide: false,
      sort: null,
    })),
    { colId: ColumnId.AverageFare, hide: false, sort: null },
    { colId: ColumnId.TotalFareRevenue, hide: false, sort: null },
    { colId: ColumnId.AuthorizationUnits, hide: false, sort: null },
    { colId: ColumnId.Protection, hide: false, sort: null },
    { colId: ColumnId.UpdatedAuthorizationUnits, hide: false, sort: null },
    { colId: ColumnId.UpdatedProtection, hide: false, sort: null },
    { colId: ColumnId.SegmentAvailability, hide: false, sort: null },
    { colId: ColumnId.SeatAvailability, hide: false, sort: null },
    { colId: ColumnId.RuleAuthorizationUnits, hide: true, sort: null },
    { colId: ColumnId.BookingAvailabilityStatus, hide: false, sort: null },
    { colId: ColumnId.Waitlist, hide: true, sort: null },
  ];
}

export function getInventoryActiveOptimisationTacticsColumns(): ColDef[] {
  return [
    CabinClassColumns.RecommendedAuthorizationUnitsColumn,
    CabinClassColumns.RecommendedProtectionColumn,
    CabinClassColumns.RecommendedProtectionDifferenceColumn,
    CabinClassColumns.RecommendedSeatAvailabilityColumn,
    CabinClassColumns.RecommendedSeatAvailabilityDifferenceColumn,
  ];
}

export function generateInventoryActiveOptimisationTacticsColumnsState(): ColumnState[] {
  return [
    { colId: ColumnId.RecommendedSeatAvailability, hide: false, sort: null },
    { colId: ColumnId.RecommendedAuthorizationUnits, hide: false, sort: null },
    { colId: ColumnId.RecommendedProtection, hide: false, sort: null },
  ];
}

export function getInventoryShadowOptimisationTacticsColumns(): ColDef[] {
  return [
    CabinClassColumns.ShadowRecommendedAuthorizationUnitsColumn,
    CabinClassColumns.ShadowRecommendedProtectionColumn,
    CabinClassColumns.ShadowRecommendedProtectionDifferenceColumn,
    CabinClassColumns.ShadowRecommendedSeatAvailabilityColumn,
    CabinClassColumns.ShadowRecommendedSeatAvailabilityDifferenceColumn,
  ];
}

export function generateInventoryShadowOptimisationTacticsColumnsState(): ColumnState[] {
  return [
    { colId: ColumnId.ShadowRecommendedSeatAvailability, hide: true, sort: null },
    { colId: ColumnId.ShadowRecommendedAuthorizationUnits, hide: true, sort: null },
    { colId: ColumnId.ShadowRecommendedProtection, hide: true, sort: null },
  ];
}

export function getInventorySharedCabinClassSourceFlightColumns(): ColDef[] {
  return [
    CabinClassColumns.CabinClassSourceBoardingsColumn,
    CabinClassColumns.CabinClassSourceFinalBookingsColumn,
    CabinClassColumns.CabinClassSourceBookingsColumn,
    CabinClassColumns.CabinClassSourceFlightRevenueColumn,
    CabinClassColumns.CabinClassSourceFlightBoardedRevenueColumn,
    CabinClassColumns.CabinClassSourceFlightFinalRevenueColumn,
    CabinClassColumns.CabinClassSourceFlightAverageFareColumn,
    CabinClassColumns.CabinClassSourceFlightBoardedAverageFareColumn,
    CabinClassColumns.CabinClassSourceFlightFinalAverageFareColumn,
  ];
}

export function generateInventorySharedCabinClassSourceFlightColumnsState(): ColumnState[] {
  return [
    { colId: ColumnId.SourceBoardings, hide: false, sort: null },
    { colId: ColumnId.SourceFinalBookings, hide: false, sort: null },
    { colId: ColumnId.SourceBookings, hide: false, sort: null },
  ];
}
