import { GridState } from '@/store/modules/user-config.module';

export function generateTourOperatorDetailsColumnDefaultState(): GridState {
  return {
    columnsState: [
      {
        colId: 'tourOperatorName',
        width: 170,
        hide: false,
        pinned: 'left',
        sort: null,
        sortIndex: null,
        aggFunc: 'showTotalsText',
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
        flex: null,
      },
      {
        colId: 'cabinCode',
        width: 79,
        hide: false,
        pinned: null,
        sort: null,
        sortIndex: null,
        aggFunc: 'sum',
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
        flex: null,
      },
      {
        colId: 'contractType',
        width: 132,
        hide: false,
        pinned: null,
        sort: null,
        sortIndex: null,
        aggFunc: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
        flex: null,
      },
      {
        colId: 'contractCode',
        width: 134,
        hide: false,
        pinned: null,
        sort: null,
        sortIndex: null,
        aggFunc: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
        flex: null,
      },
      {
        colId: 'initialBlock',
        width: 76,
        hide: false,
        pinned: null,
        sort: null,
        sortIndex: null,
        aggFunc: 'sum',
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
        flex: null,
      },
      {
        colId: 'confirmedBlock',
        width: 109,
        hide: false,
        pinned: null,
        sort: null,
        sortIndex: null,
        aggFunc: 'sum',
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
        flex: null,
      },
      {
        colId: 'blockSold',
        width: 70,
        hide: false,
        pinned: null,
        sort: null,
        sortIndex: null,
        aggFunc: 'sum',
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
        flex: null,
      },
      {
        colId: 'available',
        width: 99,
        hide: false,
        pinned: null,
        sort: null,
        sortIndex: null,
        aggFunc: 'sum',
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
        flex: null,
      },
      {
        colId: 'initialUtilization',
        width: 143,
        hide: false,
        pinned: null,
        sort: null,
        sortIndex: null,
        aggFunc: 'avg',
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
        flex: null,
      },
      {
        colId: 'confirmedUtilization',
        width: 175,
        hide: false,
        pinned: null,
        sort: null,
        sortIndex: null,
        aggFunc: 'avg',
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
        flex: null,
      },
      {
        colId: 'initialBlockRate',
        width: 108,
        hide: false,
        pinned: null,
        sort: null,
        sortIndex: null,
        aggFunc: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
        flex: null,
      },
      {
        colId: 'confirmedBlockRate',
        width: 140,
        hide: false,
        pinned: null,
        sort: null,
        sortIndex: null,
        aggFunc: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
        flex: null,
      },
      {
        colId: 'soldRevenue',
        width: 127,
        hide: false,
        pinned: null,
        sort: null,
        sortIndex: null,
        aggFunc: 'sum',
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
        flex: null,
      },
      {
        colId: 'soldAverageRate',
        width: 157,
        hide: false,
        pinned: null,
        sort: null,
        sortIndex: null,
        aggFunc: 'sum',
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
        flex: null,
      },
    ],
    isPivotMode: false,
  };
}
