import { RouteRecordRaw } from 'vue-router';

import { useDocsStore } from '@/modules/docs/store/docs.store';
import { RouteName } from '@/modules/shared/types/routes';

export const DOCS_ROUTES: RouteRecordRaw[] = [
  {
    path: 'docs',
    name: RouteName.docs,
    component: () => import('../views/DocsView.vue'),
    redirect: { name: RouteName.releaseNotes },
    children: [
      {
        path: 'release-notes',
        name: RouteName.releaseNotes,
        component: () => import('../views/ReleaseNotesView.vue'),
      },
      {
        path: 'user-guide',
        name: RouteName.userGuide,
        async beforeEnter(to, from, next) {
          const docsStore = useDocsStore();
          const version = to.query?.version;

          if (!version || typeof version !== 'string') {
            next({ name: RouteName.docs });
          } else {
            if (docsStore.userGuidesMap.size === 0) {
              await docsStore.checkIfUserGuideExistsForVersion(version);
            }

            if (docsStore.userGuidesMap.get(version)?.exists) {
              next();
            } else {
              next({ name: RouteName.docs, query: { version } });
            }
          }
        },
        component: () => import('../views/UserGuideView.vue'),
      },
    ],
  },
];
