import { ColDef, ColGroupDef } from 'ag-grid-enterprise';

import { ApiPssCapabilities, CustomerSettingsModel } from '@/modules/customer-settings/api/customer-settings.contracts';
import { FeaturesModel } from '@/modules/features';
import { UiAndApiPssCapabilities } from '@/modules/shared/configuration/pss-capabilities';

type RequiredPermissionParams = {
  colDef: ColDef;
  customerSettings: Partial<CustomerSettingsModel<ApiPssCapabilities>>;
  features: FeaturesModel;
};

/**
 * String literal type for the requiredPermission property based on Customer Settings and Features.
 * Alternatively, a custom function can be used to determine if the column should be displayed.
 */
export type RequiredPermission = (params: RequiredPermissionParams) => boolean;

/**
 * This is a global augmentation to add the requiredPermission property to the ColDef and ColGroupDef type.
 * This works because of Declaration Merging.
 */
declare module 'ag-grid-community' {
  interface AbstractColDef {
    /**
     * Custom handler to determine if the column should be displayed based on a value.
     * @example requiredPermission: (colDef) => colDef.field === 'test',
     */
    requiredPermission?: RequiredPermission;
  }
}

declare module 'ag-grid-enterprise' {
  interface AbstractColDef {
    requiredPermission?: RequiredPermission;
  }
}

type PermissionConditionParams = {
  colDef: ColDef;
  customerSettings: Partial<CustomerSettingsModel<UiAndApiPssCapabilities>>;
  features: FeaturesModel;
};

const permissionConditions: Array<({ colDef, customerSettings, features }: PermissionConditionParams) => boolean> = [
  ({ colDef }) => !colDef.requiredPermission,
  // Check if the required permission is a custom handler, and call it with the appropriate arguments
  ({ colDef, customerSettings, features }) =>
    typeof colDef.requiredPermission === 'function' && colDef.requiredPermission({ colDef, customerSettings, features }),
  // or check that the requiredPermission is unknown and no custom handler is defined
  ({ colDef }) => typeof colDef.requiredPermission !== 'function',
];

/**
 * Filters columns based on user permissions.
 * @param columns - The columns to filter.
 * @param customerSettings - The customer settings to check permissions against.
 * @param features - Engineering toggles to check permissions against.
 * @returns The filtered columns.
 */
export const filterColumnsOnPermission = (
  columns: Array<ColDef | ColGroupDef> = [],
  customerSettings: Partial<CustomerSettingsModel<UiAndApiPssCapabilities>>,
  features: FeaturesModel,
): Array<ColDef | ColGroupDef> =>
  columns
    .filter((colDef: ColDef | ColGroupDef) => permissionConditions.some((condition) => condition({ colDef, customerSettings, features })))
    .map((column: ColDef | ColGroupDef) =>
      'children' in column && column.children?.length > 0
        ? { ...column, children: filterColumnsOnPermission(column.children, customerSettings, features) }
        : column,
    );
