export const en = {
  application: {
    menu_links: {
      cluster_management: 'Cluster Management',
      customer_config: 'Customer Config',
      data_import: 'External File Importer',
      event_management: 'Event Management',
      pacing_curve_management: 'Pacing Curve Management',
      promotion_management: 'Promotion Management',
      revenue_components_management: 'Revenue Components Management',
      rules: 'Rules',
      route_management: 'Route Management',
      system_settings: 'System Settings',
      user_management: 'Users & Permissions',
    },
    sidebar_menu: {
      capture_date: 'Capture Date',
      cf_capture_date: 'CF Capture Date',
      control: 'Control',
      dashboard: 'Dashboard',
      data_science_capture_date: 'Data Science Capture Date',
      departed: 'Departed',
      details: 'Details',
      development: 'Development',
      doc_hub: 'Documentation Hub',
      flights: 'Flights',
      flowfx: 'FlowFx',
      intelligence: 'Intelligence',
      insights: 'Insights',
      last_optimizer_update: 'Last Optimizer Update',
      last_realtime_update: 'Last Realtime Update',
      overview: 'Overview',
      reporting: 'Reporting',
      ancillary_revenue_update_date: 'Ancillary Revenue Update',
      tax_revenue_update_date: 'Tax Revenue Update',
      surcharge_revenue_update_date: 'Surcharge Revenue Update',
      tour_operators_update_date: 'TO/Groups Update',
    },
  },
};
