export enum InventoryGridModel {
  code = 'code',
  parentClass = 'parentClass',
  bookings = 'bookings',
  updatedBookings = 'updatedBookings',
  groupBookings = 'groupBookings',
  bookingsExGroup = 'bookingsExGroup',
  authorizationUnits = 'authorizationUnits',
  updatedAuthorizationUnits = 'updatedAuthorizationUnits',
  isAuthorizationUnitsUpdated = 'isAuthorizationUnitsUpdated',
  protection = 'protection',
  updatedProtection = 'updatedProtection',
  isProtectionUpdated = 'isProtectionUpdated',
  ruleAuthorizationUnits = 'ruleAuthorizationUnits',
  groupPending = 'groupOptional',
  groupWaitlisted = 'groupWaitlisted',
  waitlist = 'waitlist',
  negotiatedAllottedSeats = 'negotiatedAllottedSeats',
  segmentAvailability = 'segmentAvailability',
  effectiveDate = 'effectiveDate',
  updatedEffectiveDate = 'updatedEffectiveDate',
  discontinuedDate = 'discontinuedDate',
  updatedDiscontinuedDate = 'updatedDiscontinuedDate',
  minControl = 'minControl',
  updatedMinControl = 'updatedMinControl',
  waitlistMax = 'waitlistMax',
  updatedWaitlistMax = 'updatedWaitlistMax',
  waitlistMaxPercentage = 'waitlistMaxPercentage',
  updatedWaitlistMaxPercentage = 'updatedWaitlistMaxPercentage',
  segmentLimit = 'segmentLimit',
  updatedSegmentLimit = 'updatedSegmentLimit',
  fareValue = 'fareValue',
  averageFare = 'averageFare',
  seatAvailability = 'seatAvailability',
  updatedSeatAvailability = 'updatedSeatAvailability',
  recommendedSeatAvailability = 'recommendedSeatAvailability',
  recommendedSeatAvailabilityDifference = 'recommendedSeatAvailabilityDifference',
  recommendedLowestAvailableFareClass = 'recommendedLowestAvailableFareClass',
  shadowRecommendedSeatAvailability = 'shadowRecommendedSeatAvailability',
  shadowRecommendedSeatAvailabilityDifference = 'shadowRecommendedSeatAvailabilityDifference',
  boardings = 'boardings',
  sold = 'sold',
  recommendedAuthorizationUnits = 'recommendedAuthorizationUnits',
  shadowRecommendedAuthorizationUnits = 'shadowRecommendedAuthorizationUnits',
  isAuthorizationUnitPinned = 'isAuthorizationUnitPinned',
  isProtectionPinned = 'isProtectionPinned',
  recommendedProtection = 'recommendedProtection',
  recommendedProtectionDifference = 'recommendedProtectionDifference',
  shadowRecommendedProtection = 'shadowRecommendedProtection',
  shadowRecommendedProtectionDifference = 'shadowRecommendedProtectionDifference',
  minPrice = 'minPrice',
  maxPrice = 'maxPrice',
  publishedFareValue = 'publishedFareValue',
  boardedRevenue = 'boardedRevenue',
}

export function isCabinClass(classIndex: number): boolean {
  return classIndex === 0;
}
