export const en = {
  docs: {
    title: {
      doc_hub: 'Documentation Hub',
      release_notes: 'Release Notes',
      user_guide: 'User Guide',
    },
    labels: {
      published: 'Published:',
      modified: 'Last modified:',
      using_version: 'Using Version:',
      n_a: 'N/A',
      docs_version: 'Docs Version',
      new: 'NEW',
    },
    info: {
      no_release_notes: 'No release notes for version {version} yet 👀',
      eddy_user_guide: `The Eddy User Guide is a comprehensive guide to using Eddy. It is designed to help you get started with Eddy and to provide you with
      detailed information on how to use Eddy's features.`,
    },
    headings: {
      on_this_page: 'On this page',
    },
    actions: {
      view_eddy_user_guide: 'View Eddy User Guide for version',
    },
    feedback_form: {
      feedback: {
        question: 'Are These Release Notes Helpful?',
        placeholder: 'Yes, but it’d be wonderful if...',
      },
    },
    errors: {
      failed_to_check_user_guide_for_version: 'Failed to check if User Guide is available for version: {version}',
      failed_to_create_pdf_url: 'Failed to open PDF in new window, please try again',
      failed_to_get_release_notes_for_version: 'Failed to get release notes for version: {version}',
    },
  },
};
