import { CellClassParams, ColDef, ValueGetterParams } from 'ag-grid-enterprise';

import { ColumnId, generateCabinLevelColumnId } from '@/models/enums/grid';
import { i18n } from '@/plugins/i18n';
import { FlightService } from '@/services/flight.service';
import { FormatService } from '@/services/format.service';

import { NumberColumnFilterSettings, SetColumnFilterSettings } from './base';

const { t } = i18n.global;

// A tour-operator Details Button that opens up the popup
export const generateTourOperatorDetailsColumn = (cabinCode?: string): ColDef => ({
  ...SetColumnFilterSettings,
  colId: cabinCode ? generateCabinLevelColumnId(cabinCode, ColumnId.TourOperatorDetails) : ColumnId.TourOperatorDetails,
  headerName: t('grid.columns.tour_operator.details_short'),
  headerClass: 'ag-left-aligned-header',
  headerTooltip: t('grid.columns.tour_operator.details'),
  minWidth: 70,
  width: 70,
  cellRendererParams: () => ({
    cabinCode,
  }),
  cellRenderer: 'GridTourOperatorDetailsRenderer',
  cellClass: ({ data }: CellClassParams) =>
    `ag-right-aligned-cell data-test-tour-operator-details-${data.ondId}${cabinCode ? '-' + cabinCode : ''}`,
});

// Confirmed
export const generateTourOperatorConfirmedColumn = (cabinCode?: string): ColDef => ({
  ...NumberColumnFilterSettings,
  colId: cabinCode ? generateCabinLevelColumnId(cabinCode, ColumnId.TourOperatorConfirmed) : ColumnId.TourOperatorConfirmed,
  headerName: t('grid.columns.tour_operator.confirmed_short'),
  type: 'numericColumn',
  minWidth: 40,
  width: 40,
  sortable: true,
  headerTooltip: t('grid.columns.tour_operator.confirmed'),
  cellClass: ({ data }: CellClassParams) =>
    `ag-right-aligned-cell data-test-tour-operator-confirmed-${data.ondId}${cabinCode ? '-' + cabinCode : ''}`,
  valueGetter: (params: ValueGetterParams) => {
    if (cabinCode) {
      const matchingCabin = FlightService.getMatchedCabin(params.data, cabinCode);
      return matchingCabin && matchingCabin.tourOperatorKpis?.confirmed;
    } else {
      return params.data.tourOperatorKpis?.confirmed;
    }
  },
});

// Sold
export const generateTourOperatorSoldColumn = (cabinCode?: string): ColDef => ({
  ...NumberColumnFilterSettings,
  colId: cabinCode ? generateCabinLevelColumnId(cabinCode, ColumnId.TourOperatorSold) : ColumnId.TourOperatorSold,
  headerName: t('grid.columns.tour_operator.sold_short'),
  type: 'numericColumn',
  minWidth: 40,
  width: 40,
  sortable: true,
  headerTooltip: t('grid.columns.tour_operator.sold'),
  cellClass: ({ data }: CellClassParams) =>
    `ag-right-aligned-cell data-test-tour-operator-sold-${data.ondId}${cabinCode ? '-' + cabinCode : ''}`,
  valueGetter: (params: ValueGetterParams) => {
    if (cabinCode) {
      const matchingCabin = FlightService.getMatchedCabin(params.data, cabinCode);
      return matchingCabin && matchingCabin.tourOperatorKpis?.sold;
    } else {
      return params.data.tourOperatorKpis?.sold;
    }
  },
});

// Available // calculated (Confirmed – Sold)
export const generateTourOperatorAvailableColumn = (cabinCode?: string): ColDef => ({
  ...NumberColumnFilterSettings,
  colId: cabinCode ? generateCabinLevelColumnId(cabinCode, ColumnId.TourOperatorAvailable) : ColumnId.TourOperatorAvailable,
  headerName: t('grid.columns.tour_operator.available_short'),
  type: 'numericColumn',
  minWidth: 40,
  width: 40,
  sortable: true,
  headerTooltip: t('grid.columns.tour_operator.available'),
  cellClass: ({ data }: CellClassParams) =>
    `ag-right-aligned-cell data-test-tour-operator-available-${data.ondId}${cabinCode ? '-' + cabinCode : ''}`,
  valueGetter: (params: ValueGetterParams) => {
    if (cabinCode) {
      const matchingCabin = FlightService.getMatchedCabin(params.data, cabinCode);
      return matchingCabin && matchingCabin.tourOperatorKpis?.available;
    } else {
      return params.data.tourOperatorKpis?.available;
    }
  },
});

// Initial Utilization // calculated (Sold / Initial)
export const generateTourOperatorInitialUtilizationColumn = (cabinCode?: string): ColDef => ({
  ...NumberColumnFilterSettings,
  colId: cabinCode
    ? generateCabinLevelColumnId(cabinCode, ColumnId.TourOperatorInitialUtilization)
    : ColumnId.TourOperatorInitialUtilization,
  headerName: t('grid.columns.tour_operator.initial_utilization_short'),
  type: 'numericColumn',
  minWidth: 40,
  width: 40,
  sortable: true,
  headerTooltip: t('grid.columns.tour_operator.initial_utilization'),
  cellClass: ({ data }: CellClassParams) =>
    `ag-right-aligned-cell data-test-tour-operator-initial-utilization-${data.ondId}${cabinCode ? '-' + cabinCode : ''}`,
  valueGetter: (params: ValueGetterParams) => {
    if (cabinCode) {
      const matchingCabin = FlightService.getMatchedCabin(params.data, cabinCode);
      return matchingCabin && matchingCabin.tourOperatorKpis?.initialUtilization;
    } else {
      return params.data.tourOperatorKpis?.initialUtilization;
    }
  },
});

// Confirmed Utilization // calculated (Sold / Confirmed)
export const generateTourOperatorConfirmedUtilizationColumn = (cabinCode?: string): ColDef => ({
  ...NumberColumnFilterSettings,
  colId: cabinCode
    ? generateCabinLevelColumnId(cabinCode, ColumnId.TourOperatorConfirmedUtilization)
    : ColumnId.TourOperatorConfirmedUtilization,
  headerName: t('grid.columns.tour_operator.confirmed_utilization_short'),
  type: 'numericColumn',
  minWidth: 40,
  width: 40,
  sortable: true,
  headerTooltip: t('grid.columns.tour_operator.confirmed_utilization'),
  cellClass: ({ data }: CellClassParams) =>
    `ag-right-aligned-cell data-test-tour-operator-confirmed-utilization-${data.ondId}${cabinCode ? '-' + cabinCode : ''}`,
  valueGetter: (params: ValueGetterParams) => {
    if (cabinCode) {
      const matchingCabin = FlightService.getMatchedCabin(params.data, cabinCode);
      return matchingCabin && matchingCabin.tourOperatorKpis?.confirmedUtilization;
    } else {
      return params.data.tourOperatorKpis?.confirmedUtilization;
    }
  },
});

// Confirmed Rate
export const generateTourOperatorConfirmedRateColumn = (cabinCode?: string): ColDef => ({
  ...NumberColumnFilterSettings,
  colId: cabinCode ? generateCabinLevelColumnId(cabinCode, ColumnId.TourOperatorConfirmedRate) : ColumnId.TourOperatorConfirmedRate,
  headerName: t('grid.columns.tour_operator.confirmed_rate_short'),
  type: 'numericColumn',
  minWidth: 40,
  width: 40,
  sortable: true,
  headerTooltip: t('grid.columns.tour_operator.confirmed_rate'),
  cellClass: ({ data }: CellClassParams) =>
    `ag-right-aligned-cell data-test-tour-operator-confirmed-rate-${data.ondId}${cabinCode ? '-' + cabinCode : ''}`,
  valueGetter: (params: ValueGetterParams) => {
    if (cabinCode) {
      const matchingCabin = FlightService.getMatchedCabin(params.data, cabinCode);
      return matchingCabin && matchingCabin.tourOperatorKpis?.confirmedRate;
    } else {
      return params.data.tourOperatorKpis?.confirmedRate;
    }
  },
});

// Sold Revenue
export const generateTourOperatorSoldRevenueColumn = (cabinCode?: string): ColDef => ({
  ...NumberColumnFilterSettings,
  colId: cabinCode ? generateCabinLevelColumnId(cabinCode, ColumnId.TourOperatorSoldRevenue) : ColumnId.TourOperatorSoldRevenue,
  headerName: t('grid.columns.tour_operator.sold_revenue_short'),
  type: 'numericColumn',
  minWidth: 40,
  width: 40,
  sortable: true,
  headerTooltip: t('grid.columns.tour_operator.sold_revenue'),
  cellClass: ({ data }: CellClassParams) =>
    `ag-right-aligned-cell data-test-tour-operator-sold-revenue-${data.ondId}${cabinCode ? '-' + cabinCode : ''}`,
  valueGetter: (params: ValueGetterParams) => {
    if (cabinCode) {
      const matchingCabin = FlightService.getMatchedCabin(params.data, cabinCode);
      return matchingCabin && matchingCabin.tourOperatorKpis?.soldRevenue;
    } else {
      return params.data.tourOperatorKpis?.soldRevenue;
    }
  },
});

// Sold Average Rate // calculated (Revenue / Sold)
export const generateTourOperatorSoldAverageRateColumn = (cabinCode?: string): ColDef => ({
  ...NumberColumnFilterSettings,
  colId: cabinCode ? generateCabinLevelColumnId(cabinCode, ColumnId.TourOperatorSoldAverageRate) : ColumnId.TourOperatorSoldAverageRate,
  headerName: t('grid.columns.tour_operator.sold_average_rate_short'),
  type: 'numericColumn',
  minWidth: 40,
  width: 40,
  sortable: true,
  headerTooltip: t('grid.columns.tour_operator.sold_average_rate'),
  cellClass: ({ data }: CellClassParams) =>
    `ag-right-aligned-cell data-test-tour-operator-sold-average-rate-${data.ondId}${cabinCode ? '-' + cabinCode : ''}`,
  valueGetter: (params: ValueGetterParams) => {
    if (cabinCode) {
      const matchingCabin = FlightService.getMatchedCabin(params.data, cabinCode);
      return matchingCabin?.tourOperatorKpis?.soldAverageRate
        ? FormatService.roundNumber(matchingCabin.tourOperatorKpis?.soldAverageRate, 2)
        : undefined;
    } else {
      return params.data.tourOperatorKpis?.soldAverageRate
        ? FormatService.roundNumber(params.data.tourOperatorKpis?.soldAverageRate, 2)
        : undefined;
    }
  },
});
