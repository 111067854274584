import { ColDef, ColGroupDef } from 'ag-grid-enterprise';

import { FlightLineColumnsGeneratorValuesInterface } from '@/models/columns/collections/flightlines/index';
import * as Columns from '@/models/columns/definitions';
import { CabinStructure } from '@/modules/api/application/application-contracts';
import { i18n } from '@/plugins/i18n';

const { t } = i18n.global;

export function getSharedControlSourceFlightColumns(): ColDef[] {
  return [
    Columns.SourceFlightFareCurrencyColumn,
    Columns.SourceFlightBookingsColumn,
    Columns.SourceFlightGroupBookingsColumn,
    Columns.SourceFlightBoardingsColumn,
    Columns.SourceFlightGroupBoardingsColumn,
    Columns.SourceFlightFinalBookingsColumn,
    Columns.SourceFlightFinalGroupBookingsColumn,
    Columns.SourceFlightAverageFareColumn,
    Columns.SourceFlightBoardedAverageFareColumn,
    Columns.SourceFlightFinalAverageFareColumn,
    Columns.SourceFlightRevenueColumn,
    Columns.SourceFlightBoardedRevenueColumn,
    Columns.SourceFlightFinalRevenueColumn,
  ];
}

export function getSharedControlFlightLineLegCabinColumns(generatorValues: FlightLineColumnsGeneratorValuesInterface): ColGroupDef[] {
  return generatorValues.cabins.map((cabin: CabinStructure) => ({
    headerName: `${t('leg')} | ${t('cabin')} ${cabin.code}`,
    groupId: `leg-cabin-level-metrics-${cabin.code}`,
    headerGroupComponentParams: {
      cabinCode: cabin.code,
    },
    children: [
      Columns.LegCabinSaleableCapacityColumn(cabin.code),
      Columns.LegCabinAuthorizedCapacityColumn(cabin.code),
      Columns.LegCabinAuthorizedCapacityLoadfactorColumn(cabin.code),
      Columns.LegCabinSaleableCapacityLoadfactorColumn(cabin.code),
      Columns.LegCabinOverbookingFactorColumn(cabin.code),
      Columns.LegCabinLeftoverCapacityFactorColumn(cabin.code),
      Columns.LegCabinExpectedNoShowsColumn(cabin.code),
      Columns.LegCabinRecommendedOverbookingFactorColumn(cabin.code),
      Columns.LegCabinOverbookingRiskColumn(cabin.code),
      Columns.LegCabinOverbookingAutopilotColumn(cabin.code),
    ],
  }));
}

export function getSharedFlightTourOperatorColumns(): ColGroupDef {
  return {
    headerName: `${t('general.flight')} | ${t('grid.columns.tour_operator.tour_operator')}`,
    groupId: `flight-level-nego-metrics`,
    children: [
      Columns.generateTourOperatorDetailsColumn(),
      Columns.generateTourOperatorConfirmedColumn(),
      Columns.generateTourOperatorSoldColumn(),
      Columns.generateTourOperatorAvailableColumn(),
      Columns.generateTourOperatorInitialUtilizationColumn(),
      Columns.generateTourOperatorConfirmedUtilizationColumn(),
      Columns.generateTourOperatorConfirmedRateColumn(),
      Columns.generateTourOperatorSoldRevenueColumn(),
      Columns.generateTourOperatorSoldAverageRateColumn(),
    ],
    requiredPermission(params) {
      return (
        !!params.features.tourOperators &&
        !!params.customerSettings.hasNegoAllocationEnabled &&
        !!params.customerSettings.hasTourOperatorsV2Enabled
      );
    },
  };
}

export function getSharedCabinTourOperatorColumns(generatorValues: FlightLineColumnsGeneratorValuesInterface): ColGroupDef[] {
  return generatorValues.cabins.map((cabin: CabinStructure) => ({
    headerName: `${t('general.flight')} | ${t('cabin')} ${cabin.code} ${t('grid.columns.tour_operator.tour_operator')}`,
    groupId: `flight-cabin-level-tour-operator-metrics-${cabin.code}`,
    headerGroupComponentParams: {
      cabinCode: cabin.code,
    },
    children: [
      Columns.generateTourOperatorDetailsColumn(cabin.code),
      Columns.generateTourOperatorConfirmedColumn(cabin.code),
      Columns.generateTourOperatorSoldColumn(cabin.code),
      Columns.generateTourOperatorAvailableColumn(cabin.code),
      Columns.generateTourOperatorInitialUtilizationColumn(cabin.code),
      Columns.generateTourOperatorConfirmedUtilizationColumn(cabin.code),
      Columns.generateTourOperatorConfirmedRateColumn(cabin.code),
      Columns.generateTourOperatorSoldRevenueColumn(cabin.code),
      Columns.generateTourOperatorSoldAverageRateColumn(cabin.code),
    ],
    requiredPermission(params) {
      // Show the group if
      return (
        !!params.features.tourOperators &&
        !!params.customerSettings.hasNegoAllocationEnabled &&
        !!params.customerSettings.hasTourOperatorsV2Enabled
      );
    },
  }));
}
